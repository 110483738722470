.ck-add-button {
  width: 100%;
  max-width: 540px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 78px !important;
  border: 1px dashed var(--mariner-color);
  background-color: var(--light-blue);
  gap: 5px;
  border-radius: 10px !important;
}
.ck-add-button:not([disabled]):focus,
.ck-add-button:not([disabled]):focus,
.ck-add-button:not([disabled]):hover {
  background-color: var(--light-blue) !important;
  border-color: var(--mariner-color);
}
.ck-add-button span:not(.anticon) {
  font-weight: 600;
  color: var(--primary-color);
  line-height: 30px;
  font-size: 16px !important;
}
.ck-add-button span.anticon {
  color: var(--mariner-color);
}

.ck-datepicker .ant-picker {
  width: 100%;
}
.ck-datepicker input {
  color: var(--gray, #404040);
  font-size: 16px;
}
.ck-datepicker .ant-form-item-row {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: stretch;
  width: 100%;
}
.ck-datepicker .ant-form-item-row .ant-col {
  width: 100%;
  text-align: left;
}
.ck-datepicker .ant-picker-suffix,
.ck-datepicker .ant-picker-clear {
  color: var(--primary-color); 
}
.ck-datepicker .ant-picker-suffix svg,
.ck-datepicker .ant-picker-clear svg {
  height: 18px !important;
  width: auto !important;
}
.ck-datepicker .ant-form-item-explain div {
  color: #666;
  font-size: 12px;
  line-height: 15px;
  color: var(--gray-450);
}

.ck-datepicker .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  margin-right: 0;
  color: var(--text-color);
}

/* ==========================================================================
  Popover
  ========================================================================== */
.ck-datepicker-popover {
  width: 320px;
}
.ck-datepicker-popover .ant-picker-header-view {
  color: var(--primary-color);
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
}
.ck-datepicker-popover .ant-picker-today-btn {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 36px;
  display: block;
  width: 95px;
  height: 40px;
  color: var(--gray);
  margin: 0 auto;
  border: 2px solid var(--primary-color);
  border-radius: 10px;
}
.ck-datepicker-popover .ant-picker-panel {
  display: flex;
  flex-direction: column-reverse;
}
.ck-datepicker-popover .ant-picker-header {
  border: unset;
  align-items: center;
  gap: 5px;
  width: 320px;
}
.ck-datepicker-popover .ant-picker-footer {
  border: unset;
  height: 50px;
  padding: 10px 10px 0 10px;
  width: 320px;
}
.ck-datepicker-popover .ant-picker-body {
  padding: 0px 10px 10px 10px;
  width: 320px;
}
.ck-datepicker-popover .ant-picker-content {
  width: 300px;
}
.ck-datepicker-popover .ant-picker-content thead th {
  color: var(--primary-color);
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
}
.ck-datepicker-popover .ant-picker-content tbody td {
  color: var(--gray);
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
}
.ck-datepicker-popover .ant-picker-content .ant-picker-cell-inner {
  width: 30px;
  height: 24px;
}
.ck-datepicker-popover  .ant-picker-content tbody td.ant-picker-cell-disabled {
  color: var(--gray-390) !important;
}
.ant-picker-dropdown .ant-picker-cell-selected .ant-picker-cell-inner {
  border-radius: 6px;
  background: var(--mariner-color);
  color: #fff;
}
.ck-datepicker-popover .ant-picker-prev-icon {
  left: 1px;
}
.ck-datepicker-popover .ant-picker-super-prev-icon,
.ck-datepicker-popover .ant-picker-next-icon {
  left: -1px;
}
.ck-datepicker-popover .ant-picker-super-next-icon {
  left: 2px;
}
.ck-datepicker-popover .ant-picker-header-super-next-btn,
.ck-datepicker-popover .ant-picker-header-super-prev-btn,
.ck-datepicker-popover .ant-picker-header-next-btn,
.ck-datepicker-popover .ant-picker-header-prev-btn {
  width: 24px;
  height: 24px;
  border: 1px solid var(--gray-350);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000;
}

/* ==========================================================================
  Default
  ========================================================================== */
.ck-datepicker.default {

}
.ck-datepicker.default label,
.ck-datepicker.default .ant-form-item-extra {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600 !important;
  line-height: 26px;
  height: 26px;
}
.ck-datepicker.default .ant-picker {
  padding-left: 0;
  padding-right: 0;
  border-radius: 0 !important;
  border: 0;
  border-bottom: 1px solid var(--text-color);
}
.ck-datepicker.default input::placeholder {
  text-align: left !important;
  color: var(--gray-300) !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px; 
}
.ck-datepicker.default .ant-picker.ant-picker-focused {
  box-shadow: unset;
}
.ck-datepicker.default .ant-input-affix-wrapper {
  padding-left: 0;
  padding-right: 0;
  border: 0;
  border-bottom: 1px solid var(--text-color);
  border-radius: 0px !important;
}
.ck-datepicker.default .ant-input-affix-wrapper input {
  border: 0 !important;
}

/* ==========================================================================
  Rounded
  ========================================================================== */
.ck-datepicker.rounded {
  color: #fff;
}
.ck-datepicker.rounded label,
.ck-datepicker.rounded .ant-form-item-extra {
  color: #fff;
  font-weight: 600 !important;
  font-weight: 600;
  line-height: 26px;
  height: 26px;
}
.ck-datepicker.rounded .ant-picker {
  border-radius: 8px;
  height: 44px;
  background: var(--white);
}
.ck-datepicker.rounded input::placeholder {
  text-align: left !important;
  color: var(--gray) !important;
}
.ck-datepicker.rounded .ant-input-affix-wrapper {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 8px;
  overflow: hidden;
}
.ck-datepicker.rounded .ant-input-clear-icon,
.ck-datepicker.rounded .ant-input-clear-icon svg {
  height: 18px;
  width: auto;
}
.ck-datepicker.rounded .ant-form-item-explain {
  margin-top: 5px;
  color: var(--white);
  font-size: 16px;
  line-height: 20px;
}
.ck-datepicker.rounded .ant-picker-suffix svg {
  height: 18px !important;
  width: auto !important;
}
.ck-datepicker.rounded .ant-form-item-explain div,
.ck-datepicker.rounded .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  color: #fff;
}

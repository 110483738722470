.ck-checkbox .ant-checkbox-wrapper .mobile-link {
  display: none;
  text-decoration: underline;
}
.ck-checkbox .ant-checkbox-wrapper .desktop-link {
  display: inline-block;
  text-decoration: underline;
}
@media (max-width: 767px) {
  .ck-checkbox .ant-checkbox-wrapper .mobile-link {
    display: inline-block;
  }
  .ck-checkbox .ant-checkbox-wrapper .desktop-link {
    display: none;
  }
}

.ck-checkbox.no-margin {
  margin: 0;
}

.ck-checkbox .ant-form-item-row {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: stretch;
  width: 100%;
}
.ck-checkbox .ant-form-item-row .ant-col {
  width: 100%;
  text-align: left;
}
.ck-checkbox label {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  height: 26px;
}

.ck-checkbox .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner,
.ck-checkbox .ant-checkbox-wrapper .ant-checkbox.ant-checkbox-disabled .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-color: var(--primary-color);
  border-radius: 2px;
  border-width: 2px;
}
.ck-checkbox .ant-checkbox-wrapper .ant-checkbox.ant-checkbox-disabled .ant-checkbox-inner {
  border-color: rgba(var(--primary-color-rgb), 0.5) !important;
}

.ck-checkbox .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ck-checkbox .ant-checkbox-wrapper .ant-checkbox:hover .ant-checkbox-inner,
.ck-checkbox .ant-checkbox-wrapper .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--primary-color);
}

.ck-checkbox .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner::after {
  transform: rotate(45deg) scale(1.2) translate(-35%, -50%);
}
.ck-checkbox .ant-checkbox-wrapper.--inline {
  display: flex;
  align-items: center;
}

/* positive | default */
.ck-checkbox .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: var(--secondary-dark-color);
}
.ck-checkbox.wrapper-with-label .ant-checkbox-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}
.ck-checkbox.wrapper-with-label .ant-checkbox-wrapper span:not(.ant-checkbox) {
  line-height: 24px;
  margin-bottom: 0;
  padding: 0;
}

.ck-button-group {
  width: 100%;
}
.ck-button-group .ant-form-item-row {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: stretch;
  width: 100%;
}
.ck-button-group .ant-form-item-row .ant-col {
  width: 100%;
  text-align: left;
}
.ck-button-group label,
.ck-button-group .ant-form-item-extra {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600 !important;
  line-height: 26px;
  height: 26px;
}
.ck-button-group .ant-form-item-control-input-content {
  display: flex;
  gap: 10px;
}

/* ==========================================================================
  Buttons
  ========================================================================== */
.ck-button-group .ant-btn {
  border: 2px solid var(--primary-color);
  background: #fff;
  width: auto;
  min-height: 40px;
  border-radius: 8px !important;
  transition: all 0.5s ease;
  padding: 5px 16px;
}
.ck-button-group .ant-btn span:not(.anticon) {
  color: var(--gray);
  font-size: 16px !important;
  line-height: 26px;
  font-weight: 600;
}
.ck-button-group .ant-btn span.anticon svg {
  height: 24px;
  width: auto;
}
/* Selected */
.ck-button-group .ant-btn.selected {
  background: var(--primary-color);
}
.ck-button-group .ant-btn.selected span {
  color: #fff;
}

/* Active */
.ck-button-group .ant-btn:not([disabled]):not(.selected):focus {
  border-color: var(--mariner-dark-color) !important;
}
.ck-button-group .ant-btn:not([disabled]):not(.selected):focus span {
  color: var(--mariner-dark-color) !important;
}

/* Hover */
.ck-button-group .ant-btn:not([disabled]):not(.selected):hover,
.ck-button-group .ant-btn:not([disabled]):not(.selected):active {
  border-color: var(--mariner-color) !important;
}
.ck-button-group .ant-btn:not([disabled]):not(.selected):hover span,
.ck-button-group .ant-btn:not([disabled]):not(.selected):active span {
  color: var(--mariner-color) !important;
}

/* Disabled */
.ck-button-group .ant-btn[disabled],
.ck-button-group .ant-btn[disabled] {
  border-color: rgba(var(--primary-color-rgb), 0.5) !important;
}
.ck-button-group .ant-btn[disabled] span,
.ck-button-group .ant-btn[disabled] span {
  color: rgba(var(--primary-color-rgb), 0.5) !important;
}
.ck-button-group .ant-btn.iconed {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

/* ==========================================================================
  Orientation
  ========================================================================== */
/* ==========================================================================
  Orientation
  "row" | "column" | "row-reverse" | "column-reverse"
  ========================================================================== */
.ck-button-group.orientation-row .ant-form-item-control-input-content {
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between;
}
.ck-button-group.orientation-column .ant-form-item-control-input-content {
  flex-direction: column !important;
}
.ck-button-group.orientation-row-reverse .ant-form-item-control-input-content {
  flex-direction: row-reverse !important;
  align-items: center;
  justify-content: space-between;
}
.ck-button-group.orientation-column-reverse
  .ant-form-item-control-input-content {
  flex-direction: column-reverse !important;
}
@media (min-width: 769px) {
  .ck-button-group.orientation-desktop-row
    .ant-form-item-control-input-content {
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;
  }
  .ck-button-group.orientation-desktop-column
    .ant-form-item-control-input-content {
    flex-direction: column !important;
  }
  .ck-button-group.orientation-desktop-row-reverse
    .ant-form-item-control-input-content {
    flex-direction: row-reverse !important;
    align-items: center;
    justify-content: space-between;
  }
  .ck-button-group.orientation-desktop-column-reverse
    .ant-form-item-control-input-content {
    flex-direction: column-reverse !important;
  }
}
@media (max-width: 768px) {
  .ck-button-group.orientation-responsive-row
    .ant-form-item-control-input-content {
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;
  }
  .ck-button-group.orientation-responsive-column-reverse
    .ant-form-item-control-input-content {
    flex-direction: column !important;
  }
  .ck-button-group.orientation-responsive-row-reverse
    .ant-form-item-control-input-content {
    flex-direction: row-reverse !important;
    align-items: center;
    justify-content: space-between;
  }
  .ck-button-group.orientation-responsive-column-reverse
    .ant-form-item-control-input-content {
    flex-direction: column-reverse !important;
  }
}

/* ==========================================================================
  sizes
  ========================================================================== */
.ck-button-group.size-full .ant-btn {
  width: 100%;
}

.ant-btn {
  border-radius: 8px;
}

/* ==========================================================================
  Base styles
  ========================================================================== */
.ck-button {
  width: auto;
  min-height: 40px;
  border-radius: 8px !important;
  transition: all 0.5s ease;
  padding: 5px 16px;
}

/* With buttons */
.ck-button.iconed {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.ck-button.iconed > span {
  margin: 0;
}

/* Underlined */
.ck-button.underlined span:not(.anticon) {
  text-decoration: underline;
}

/* Cicled */
.ck-button.ant-btn-circle {
  border-radius: 50% !important;
}

.ck-button > span:not(.anticon) {
  font-size: 16px !important;
  line-height: 28px;
  font-weight: 400;
}
.ck-button > span.anticon svg {
  height: 24px;
  width: auto;
}
.ck-button.ant-btn-link,
.ck-button.ant-btn-text {
  padding: 0;
  min-height: 25px;
  height: auto;
}

/* ant-btn-block */
.ck-button.ant-btn-block {
  width: 240px;
}


/* large button */
.ck-button.ant-btn-lg > span {
  font-weight: 600;
}

/* small button */
.ck-button.ant-btn-sm {
  min-height: 24px;
  padding: 1px 8px;
}
.ck-button.ant-btn-sm > span:not(.anticon) {
  font-size: 14px !important;
  line-height: 22px !important;
}
.ck-button.ant-btn-sm > span.anticon {
  height: 22px;
}
.ck-button.ant-btn-sm > span.anticon svg {
  height: 22px;
  width: auto;
}
.ck-button.ant-btn-sm.ant-btn-block {
  width: 240px;
}

/* ==========================================================================
  Icon only
  ========================================================================== */
.ck-button.ant-btn-icon-only {
  width: 36px;
  height: 36px;
  padding: 5px;
}
.ck-button.ant-btn-icon-only svg {
  width: 26px;
  height: auto;
}


/* ==========================================================================
  Primary color
  ========================================================================== */
/*
  Primary color - default button 
  ========================================================================== */
.ck-button.ant-btn-default.primary {
  background: var(--primary-color);
  border-color: var(--primary-color);
}
.ck-button.ant-btn-default.primary > span {
  color: #fff;
}

/* Active */
.ck-button.ant-btn-default.primary:not([disabled]):focus {
  background-color: var(--mariner-dark-color) !important;
  border-color: var(--mariner-dark-color) !important;
}

/* Hover */
.ck-button.ant-btn-default.primary:not([disabled]):hover,
.ck-button.ant-btn-default.primary:not([disabled]):active {
  background-color: var(--mariner-color) !important;
  border-color: var(--mariner-color) !important;
}

/* Disabled */
.ck-button.ant-btn-default.primary[disabled] {
  border-color: rgba(var(--primary-color-rgb), 0.5) !important;
  background: rgba(var(--primary-color-rgb), 0.5) !important;
}
.ck-button.ant-btn-default.primary[disabled] > span {
  color: #fff;
}

/*
Primary color - primary button - dashed
========================================================================== */
.ck-button.ant-btn-primary.primary {
  background: transparent;
  border-color: var(--primary-color);
}
.ck-button.ant-btn-dashed.primary {
  background: transparent;
  border-style: dashed;
  border-color: var(--primary-color);
}
.ck-button.ant-btn-primary.primary > span,
.ck-button.ant-btn-dashed.primary > span {
  color: var(--primary-color);
}

/* Active */
.ck-button.ant-btn-primary.primary:not([disabled]):focus,
.ck-button.ant-btn-dashed.primary:not([disabled]):focus {
  border-color: var(--mariner-dark-color) !important;
}
.ck-button.ant-btn-primary.primary:not([disabled]):focus > span,
.ck-button.ant-btn-dashed.primary:not([disabled]):focus > span {
  color: var(--mariner-dark-color) !important;
}

 /* Hover */
.ck-button.ant-btn-primary.primary:not([disabled]):hover,
.ck-button.ant-btn-primary.primary:not([disabled]):active,
.ck-button.ant-btn-dashed.primary:not([disabled]):hover,
.ck-button.ant-btn-dashed.primary:not([disabled]):active {
  border-color: var(--mariner-color) !important;
}
.ck-button.ant-btn-primary.primary:not([disabled]):hover > span,
.ck-button.ant-btn-primary.primary:not([disabled]):active > span,
.ck-button.ant-btn-dashed.primary:not([disabled]):hover > span,
.ck-button.ant-btn-dashed.primary:not([disabled]):active > span {
  color: var(--mariner-color) !important;
}

/* Disabled */
.ck-button.ant-btn-primary.primary[disabled],
.ck-button.ant-btn-dashed.primary[disabled] {
  border-color: rgba(var(--primary-color-rgb), 0.5) !important;
}
.ck-button.ant-btn-primary.primary[disabled] > span,
.ck-button.ant-btn-dashed.primary[disabled] > span {
  color: rgba(var(--primary-color-rgb), 0.5) !important;
}
/*
  Primary color - link button   text button 
  ========================================================================== */
.ck-button.ant-btn-text.primary > span {
  color: var(--primary-color);
}
.ck-button.ant-btn-link.primary > span {
  color: var(--primary-color);
  text-decoration: underline;
}
/* Active */
.ck-button.ant-btn-text.primary:not([disabled]):focus > span,
.ck-button.ant-btn-link.primary:not([disabled]):focus > span {
  color: var(--mariner-dark-color) !important;
}
/* Hover */
.ck-button.ant-btn-link.primary:not([disabled]):hover > span,
.ck-button.ant-btn-link.primary:not([disabled]):active > span,
.ck-button.ant-btn-text.primary:not([disabled]):hover > span,
.ck-button.ant-btn-text.primary:not([disabled]):active > span {
  color: var(--mariner-color) !important;
}

/* Disabled */
.ck-button.ant-btn-link.primary[disabled] > span,
.ck-button.ant-btn-text.primary[disabled] > span {
  color: rgba(var(--primary-color-rgb), 0.5) !important;
}


/*
  Secondary
  ========================================================================== */
.ck-button.ant-btn-default.secondary {
  background: var(--secondary-dark-color);
  border-color: var(--secondary-dark-color);
}
.ck-button.ant-btn-default.secondary > span {
  color: #fff;
}

/* Active */ /* Hover */
.ck-button.ant-btn-default.secondary:not([disabled]):focus,
.ck-button.ant-btn-default.secondary:not([disabled]):hover,
.ck-button.ant-btn-default.secondary:not([disabled]):active {
  background-color: var(--secondary-color) !important;
}

/* Disabled */
.ck-button.ant-btn-default.secondary[disabled] {
  border-color: rgba(var(--secondary-dark-color-rgb), 0.5) !important;
  background: rgba(var(--secondary-dark-color-rgb), 0.5) !important;
}
.ck-button.ant-btn-default.secondary[disabled] > span {
  color: #fff;
}

/*  
  secondary color - primary button - dashed
  ========================================================================== */
.ck-button.ant-btn-primary.secondary {
  background: transparent;
  border-color: var(--secondary-dark-color);
}
.ck-button.ant-btn-dashed.secondary {
  background-color: transparent;
  border-color: var(--secondary-dark-color);
  border-style: dashed;
}
.ck-button.ant-btn-primary.secondary > span,
.ck-button.ant-btn-dashed.secondary > span {
  color: var(--secondary-dark-color);
}

/* Active */ /* Hover */
.ck-button.ant-btn-primary.secondary:not([disabled]):focus,
.ck-button.ant-btn-dashed.secondary:not([disabled]):focus,
.ck-button.ant-btn-primary.secondary:not([disabled]):hover,
.ck-button.ant-btn-primary.secondary:not([disabled]):active,
.ck-button.ant-btn-dashed.secondary:not([disabled]):hover,
.ck-button.ant-btn-dashed.secondary:not([disabled]):active {
  border-color: var(--secondary-color) !important;
}
.ck-button.ant-btn-primary.secondary:not([disabled]):focus > span,
.ck-button.ant-btn-dashed.secondary:not([disabled]):focus > span,
.ck-button.ant-btn-primary.secondary:not([disabled]):hover > span,
.ck-button.ant-btn-primary.secondary:not([disabled]):active > span,
.ck-button.ant-btn-dashed.secondary:not([disabled]):hover > span,
.ck-button.ant-btn-dashed.secondary:not([disabled]):active > span {
  color: var(--secondary-color) !important;
}

/* Disabled */
.ck-button.ant-btn-primary.secondary[disabled],
.ck-button.ant-btn-dashed.secondary[disabled] {
  border-color: rgba(var(--secondary-dark-color-rgb), 0.5) !important;
}
.ck-button.ant-btn-primary.secondary[disabled] > span,
.ck-button.ant-btn-dashed.secondary[disabled] > span {
  color: rgba(var(--secondary-dark-color-rgb), 0.5) !important;
}

/*
  Secondary color - link button  - text button
  ========================================================================== */
.ck-button.ant-btn-link.secondary > span {
  text-decoration: underline;
}
.ck-button.ant-btn-link.secondary > span,
.ck-button.ant-btn-text.secondary > span {
  color: var(--secondary-dark-color);
}

/* Active */ /* Hover */
.ck-button.ant-btn-link.secondary:not([disabled]):focus > span,
.ck-button.ant-btn-link.secondary:not([disabled]):hover > span,
.ck-button.ant-btn-link.secondary:not([disabled]):active > span,
.ck-button.ant-btn-text.secondary:not([disabled]):focus > span,
.ck-button.ant-btn-text.secondary:not([disabled]):hover > span,
.ck-button.ant-btn-text.secondary:not([disabled]):active > span {
  color: var(--secondary-color) !important;
}

/* Disabled */
.ck-button.ant-btn-link.secondary[disabled] > span,
.ck-button.ant-btn-text.secondary[disabled] > span {
  color: rgba(var(--secondary-dark-color-rgb), 0.5) !important;
}

/*
  Danger
  ========================================================================== */
.ck-button.ant-btn-default.danger {
  background: var(--error-color);
  border-color: var(--error-color);
}
.ck-button.ant-btn-default.danger > span {
  color: #fff;
}

/* Active */ /* Hover */
.ck-button.ant-btn-default.danger:not([disabled]):focus,
.ck-button.ant-btn-default.danger:not([disabled]):hover,
.ck-button.ant-btn-default.danger:not([disabled]):active {
  background: var(--error-dark-color) !important;
  border-color: var(--error-dark-color) !important;
}

/* Disabled */
.ck-button.ant-btn-default.danger[disabled] {
  border-color: var(--black-20);
  background: var(--black-20);
}
.ck-button.ant-btn-default.danger[disabled] > span {
  color: #fff;
}

/*
  secondary color - primary button - dashed
  ========================================================================== */
.ck-button.ant-btn-primary.danger {
  background: transparent;
  border-color: var(--error-color);
}
.ck-button.ant-btn-dashed.danger {
  background-color: transparent;
  border-color: var(--error-color);
  border-style: dashed;
}
.ck-button.ant-btn-primary.danger > span,
.ck-button.ant-btn-dashed.danger > span {
  color: var(--error-color);
}
  
/* Active */ /* Hover */
.ck-button.ant-btn-primary.danger:not([disabled]):focus,
.ck-button.ant-btn-dashed.danger:not([disabled]):focus,
.ck-button.ant-btn-primary.danger:not([disabled]):hover,
.ck-button.ant-btn-primary.danger:not([disabled]):active,
.ck-button.ant-btn-dashed.danger:not([disabled]):hover,
.ck-button.ant-btn-dashed.danger:not([disabled]):active {
  border-color: var(--error-dark-color) !important;
}
.ck-button.ant-btn-primary.danger:not([disabled]):focus > span,
.ck-button.ant-btn-dashed.danger:not([disabled]):focus > span,
.ck-button.ant-btn-primary.danger:not([disabled]):hover > span,
.ck-button.ant-btn-primary.danger:not([disabled]):active > span,
.ck-button.ant-btn-dashed.danger:not([disabled]):hover > span,
.ck-button.ant-btn-dashed.danger:not([disabled]):active > span {
  color: var(--error-dark-color) !important;
}

/* Disabled */
.ck-button.ant-btn-primary.danger[disabled],
.ck-button.ant-btn-dashed.danger[disabled] {
  border-color: var(--black-20);
}
.ck-button.ant-btn-primary.danger[disabled] > span,
.ck-button.ant-btn-dashed.danger[disabled] > span {
  color: var(--black-20);
}
  
  /*
    danger color - link button  - text button
    ========================================================================== */
.ck-button.ant-btn-link.danger > span {
  text-decoration: underline;
}
.ck-button.ant-btn-link.danger > span,
.ck-button.ant-btn-text.danger > span {
  color: var(--error-color);
}

/* Active */ /* Hover */
.ck-button.ant-btn-link.danger:not([disabled]):focus > span,
.ck-button.ant-btn-link.danger:not([disabled]):hover > span,
.ck-button.ant-btn-link.danger:not([disabled]):active > span,
.ck-button.ant-btn-text.danger:not([disabled]):focus > span,
.ck-button.ant-btn-text.danger:not([disabled]):hover > span,
.ck-button.ant-btn-text.danger:not([disabled]):active > span {
  color: var(--error-dark-color) !important;
}

/* Disabled */
.ck-button.ant-btn-link.danger[disabled] > span,
.ck-button.ant-btn-text.danger[disabled] > span {
  color: var(--black-20);
}

    
/*
white
========================================================================== */
.ck-button.ant-btn-default.white {
  background: #fff;
  border-color: #fff;
}
.ck-button.ant-btn-default.white > span {
  color: var(--primary-color);
}

/* Active */ /* Hover */
.ck-button.ant-btn-default.white:not([disabled]):focus,
.ck-button.ant-btn-default.white:not([disabled]):hover,
.ck-button.ant-btn-default.white:not([disabled]):active {
  background: rgba(255, 255, 255, 0.8) !important;
  border-color: rgba(255, 255, 255, 0.8) !important;
}

/* Disabled */
.ck-button.ant-btn-default.white[disabled] {
  border-color: var(--black-20);
  background: var(--black-20);
}
.ck-button.ant-btn-default.white[disabled] > span {
  color: #fff;
}


/*
  white color - primary button - dashed
  ========================================================================== */
.ck-button.ant-btn-primary.white {
  background: transparent;
  border-color: #fff;
}
.ck-button.ant-btn-dashed.white {
  background-color: transparent;
  border-color: #fff;
  border-style: dashed;
}
.ck-button.ant-btn-primary.white > span,
.ck-button.ant-btn-dashed.white > span {
  color: #fff;
}
  
/* Active */ /* Hover */
.ck-button.ant-btn-primary.white:not([disabled]):focus,
.ck-button.ant-btn-dashed.white:not([disabled]):focus,
.ck-button.ant-btn-primary.white:not([disabled]):hover,
.ck-button.ant-btn-primary.white:not([disabled]):active,
.ck-button.ant-btn-dashed.white:not([disabled]):hover,
.ck-button.ant-btn-dashed.white:not([disabled]):active {
  border-color: rgba(255, 255, 255, 0.8) !important;
}
.ck-button.ant-btn-primary.white:not([disabled]):focus > span,
.ck-button.ant-btn-dashed.white:not([disabled]):focus > span,
.ck-button.ant-btn-primary.white:not([disabled]):hover > span,
.ck-button.ant-btn-primary.white:not([disabled]):active > span,
.ck-button.ant-btn-dashed.white:not([disabled]):hover > span,
.ck-button.ant-btn-dashed.white:not([disabled]):active > span {
  color: rgba(255, 255, 255, 0.8) !important;
}

/* Disabled */
.ck-button.ant-btn-primary.white[disabled],
.ck-button.ant-btn-dashed.white[disabled] {
  border-color: var(--black-20);
}
.ck-button.ant-btn-primary.white[disabled] > span,
.ck-button.ant-btn-dashed.white[disabled] > span {
  color: var(--black-20);
}


/*
  white color - link button  - text button
  ========================================================================== */
.ck-button.ant-btn-link.white > span {
  text-decoration: underline;
}
.ck-button.ant-btn-link.white > span,
.ck-button.ant-btn-text.white > span {
  color: #fff;
}

/* Active */ /* Hover */
.ck-button.ant-btn-link.white:not([disabled]):focus > span,
.ck-button.ant-btn-link.white:not([disabled]):hover > span,
.ck-button.ant-btn-link.white:not([disabled]):active > span,
.ck-button.ant-btn-text.white:not([disabled]):focus > span,
.ck-button.ant-btn-text.white:not([disabled]):hover > span,
.ck-button.ant-btn-text.white:not([disabled]):active > span {
  color: rgba(255, 255, 255, 0.8) !important;
}

/* Disabled */
.ck-button.ant-btn-link.white[disabled] > span,
.ck-button.ant-btn-text.white[disabled] > span {
  color: var(--black-20);
}


/* ==========================================================================
  mariner color
  ========================================================================== */
/*
  mariner color - default button 
  ========================================================================== */
.ck-button.ant-btn-default.mariner {
  background: var(--mariner-color);
  border-color: var(--mariner-color);
}
.ck-button.ant-btn-default.mariner > span {
  color: #fff;
}

/* Active *//* Hover */
.ck-button.ant-btn-default.mariner:not([disabled]):focus,
.ck-button.ant-btn-default.mariner:not([disabled]):hover,
.ck-button.ant-btn-default.mariner:not([disabled]):active {
  background: var(--mariner-dark-color) !important;
}

/* Disabled */
.ck-button.ant-btn-default.mariner[disabled] {
  border-color: var(--black-20);
  background: var(--black-20);
}
.ck-button.ant-btn-default.mariner[disabled] > span {
  color: #fff;
}

/*
mariner color - mariner button - dashed
========================================================================== */
.ck-button.ant-btn-primary.mariner {
  background: transparent;
  border-color: var(--mariner-color);
}
.ck-button.ant-btn-dashed.mariner {
  background: transparent;
  border-style: dashed;
  border-color: var(--mariner-color);
}
.ck-button.ant-btn-primary.mariner > span,
.ck-button.ant-btn-dashed.mariner > span {
  color: var(--mariner-color);
}
  
/* Active *//* Hover */
.ck-button.ant-btn-primary.mariner:not([disabled]):focus,
.ck-button.ant-btn-dashed.mariner:not([disabled]):focus,
.ck-button.ant-btn-primary.mariner:not([disabled]):hover,
.ck-button.ant-btn-primary.mariner:not([disabled]):active,
.ck-button.ant-btn-dashed.mariner:not([disabled]):hover,
.ck-button.ant-btn-dashed.mariner:not([disabled]):active{
  border-color: var(--mariner-dark-color) !important;
}
.ck-button.ant-btn-primary.mariner:not([disabled]):focus > span,
.ck-button.ant-btn-dashed.mariner:not([disabled]):focus > span,
.ck-button.ant-btn-primary.mariner:not([disabled]):hover > span,
.ck-button.ant-btn-primary.mariner:not([disabled]):active > span,
.ck-button.ant-btn-dashed.mariner:not([disabled]):hover > span,
.ck-button.ant-btn-dashed.mariner:not([disabled]):active > span {
  color: var(--mariner-dark-color) !important;
}

/* Disabled */
.ck-button.ant-btn-primary.mariner[disabled],
.ck-button.ant-btn-dashed.mariner[disabled] {
  border-color: var(--black-20);
}
.ck-button.ant-btn-primary.mariner[disabled] > span,
.ck-button.ant-btn-dashed.mariner[disabled] > span {
  color: var(--black-20);
}
/*
  Mariner color - link button  -  - text button 
  ========================================================================== */
.ck-button.ant-btn-link.mariner > span {
  text-decoration: underline;
}
.ck-button.ant-btn-link.mariner > span,
.ck-button.ant-btn-text.mariner > span {
  color: var(--mariner-color);
}
  
/* Active *//* Hover */
.ck-button.ant-btn-link.mariner:not([disabled]):focus > span,
.ck-button.ant-btn-link.mariner:not([disabled]):hover > span,
.ck-button.ant-btn-link.mariner:not([disabled]):active > span,
.ck-button.ant-btn-text.mariner:not([disabled]):focus > span,
.ck-button.ant-btn-text.mariner:not([disabled]):hover > span,
.ck-button.ant-btn-text.mariner:not([disabled]):active > span {
  color: var(--mariner-dark-color) !important;
}
/* Disabled */
.ck-button.ant-btn-link.mariner[disabled] > span,
.ck-button.ant-btn-text.mariner[disabled] > span {
  color: var(--black-20);
}



/* ==========================================================================
  Icons personalization
  ========================================================================== */
.ck-button .anticon.caret-up svg,
.ck-button .anticon.caret-down svg,
.ck-button .anticon.arrow-left svg,
.ck-button .anticon.arrow-right svg {
  width: 24px;
  height: auto;
}

.icons-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}

.icons-container .icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #444;
  padding: 10px;
  width: 150px;
  border-radius: 10px;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
  text-align: center;
  -webkit-box-shadow: 2px 2px 5px 0px rgba(68,68,68,0.5);
  -moz-box-shadow: 2px 2px 5px 0px rgba(68,68,68,0.5);
  box-shadow: 2px 2px 5px 0px rgba(68,68,68,0.5);
  background: #eee;
  gap: 10px;
  cursor: pointer;
  transition: all 0.5s ease;
}
.icons-container .icon-container:hover {
  transform: scale(1.05);
}
.icons-container .anticon {
  color: var(--primary-color);
}
.icons-container .anticon svg {
  width: 40px;
  height: auto;
}
.icons-container .icon-name {
  /* 
  background-color: #444;
  color: #fff; 
  */
  color: #444;
  font-size: 16px;
  font-weight: 600;
  word-break: break-word;
  margin: 0 auto;
  padding: 5px;
  border-radius: 5px;
  
}

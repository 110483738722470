.ck-switch-text .react-switch-bg {
  font-weight: 600;
  font-size: 16px;
  text-align: right;
  margin-left: 10px;
  border-radius: 4px;
  border: 1px solid #E6E7E8;
  background: #F1F2F2 !important;
}
.ck-switch-text .react-switch-handle,
.ck-switch-text .react-switch-bg div,
.ck-switch-text .react-switch-handle div {
  width: 141px !important;
  border-radius: 4px !important;
}
.ck-switch-text .react-switch-handle {
  background: var(--primary-color) !important;
  top: 0px !important;
  transform: translateX(0) !important;
}
.ck-switch-text.checked .react-switch-handle {
  transform: translateX(141px) !important;
}
.ck-switch-text p {
  color: #aaaaaa;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 40px;
  margin: 0;
  text-align: center;
}
.ck-switch-text .react-switch-handle p {
  color: #fff;
}
